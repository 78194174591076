import {AuthSession} from 'aws-amplify/auth'

import {clientAuthSession} from './auth-session/client'
import {serverAuthSession} from './auth-session/server'

export async function getAccessToken(): Promise<string> {
  let session: AuthSession

  if (typeof window === 'undefined') {
    session = await serverAuthSession()
  } else {
    session = await clientAuthSession()
  }

  const accessToken = session?.tokens?.accessToken.toString()

  return accessToken ?? ''
}
