'use client'

import {PinpointService} from './amplify/pinpoint.service'
import {
  AnalyticsEvent,
  IAnalyticsService,
  UserProfile,
} from './analytics.interface'
import {MixpanelService} from './providers/mixpanel.service'
import {SegmentService} from './providers/segment.service'

export class AnalyticsService implements IAnalyticsService {
  private static providers: IAnalyticsService[] = []
  private static initialized = false

  constructor() {
    // Only initialize client-side providers if window is available
    if (typeof window !== 'undefined' && !AnalyticsService.initialized) {
      AnalyticsService.initialized = true

      // Initialize analytics providers
      AnalyticsService.providers.push(new PinpointService())

      // Add browser-only providers
      if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
        AnalyticsService.providers.push(
          new MixpanelService(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN),
        )
      }

      if (process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
        AnalyticsService.providers.push(
          new SegmentService(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY),
        )
      }
    }
  }

  /**
   * Record an event across all analytics providers
   */
  async recordEvent(event: AnalyticsEvent): Promise<void> {
    const errors: Error[] = []

    await Promise.all(
      AnalyticsService.providers.map(async (provider) => {
        try {
          await provider.recordEvent(event)
        } catch (error) {
          errors.push(error as Error)
          console.error(`Error recording event in provider:`, error)
        }
      }),
    )

    if (errors.length === AnalyticsService.providers.length) {
      throw new Error('All analytics providers failed to record event')
    }
  }

  /**
   * Identify a user across all analytics providers
   */
  async identifyUser(userProfile: UserProfile): Promise<void> {
    const errors: Error[] = []

    await Promise.all(
      AnalyticsService.providers.map(async (provider) => {
        try {
          await provider.identifyUser(userProfile)
        } catch (error) {
          errors.push(error as Error)
          console.error(`Error identifying user in provider:`, error)
        }
      }),
    )

    if (errors.length === AnalyticsService.providers.length) {
      throw new Error('All analytics providers failed to identify user')
    }
  }

  /**
   * Flush events across all analytics providers
   */
  async flushEvents(): Promise<void> {
    const errors: Error[] = []

    await Promise.all(
      AnalyticsService.providers.map(async (provider) => {
        try {
          await provider.flushEvents()
        } catch (error) {
          errors.push(error as Error)
          console.error(`Error flushing events in provider:`, error)
        }
      }),
    )

    if (errors.length === AnalyticsService.providers.length) {
      throw new Error('All analytics providers failed to flush events')
    }
  }
}
