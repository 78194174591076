import {Amplify} from 'aws-amplify'
import {flushEvents, record} from 'aws-amplify/analytics/personalize'

import {getBrowserContext} from '../utils/browser-context'

export interface PersonalizeEvent {
  eventType: string
  userId: string
  properties: {
    itemId: string
    productId?: string
    eventValue?: string
    device?: string
    location?: string
  }
}

export class PersonalizeService {
  /**
   * Record an event in Amazon Personalize
   */
  async recordEvent(event: PersonalizeEvent): Promise<void> {
    try {
      // Todo: Configure Amplify with Personalize settings globally
      const analyticsConfig = {
        Personalize: {
          trackingId:
            process.env.NEXT_PUBLIC_AMPLIFY_PERSONALIZE_TRACKING_ID ??
            'b24226df-0c74-42e6-93fa-9141705a6d92',
          region: process.env.NEXT_PUBLIC_AMPLIFY_REGION ?? 'us-west-2',
          flushSize: 10,
          flushInterval: 5000,
        },
      }
      Amplify.configure({
        ...Amplify.getConfig(),
        Analytics: {
          ...Amplify.getConfig().Analytics,
          ...analyticsConfig,
        },
      })

      const personalizeEvent = {
        ...event,
        properties: {
          ...event.properties,
          device: getBrowserContext().device,
          location: getBrowserContext().location,
        },
      }
      await record(personalizeEvent)
      // Use debug level logging without sensitive data
      console.debug(`Personalize event recorded: ${personalizeEvent.eventType}`)
    } catch (error) {
      console.error('Error recording Personalize event:', error)
      throw error
    }
  }

  /**
   * Manually flush events buffer
   */
  async flushEvents(): Promise<void> {
    try {
      await flushEvents()
    } catch (error) {
      console.error('Error flushing Personalize events:', error)
      throw error
    }
  }
}

// Create a singleton instance
export const personalizeService = new PersonalizeService()
