'use client'

import {Analytics} from '@segment/analytics-next'

import {
  AnalyticsEvent,
  IAnalyticsService,
  UserProfile,
} from '../analytics.interface'

let analytics: Analytics | null = null

export class SegmentService implements IAnalyticsService {
  constructor(writeKey: string) {
    if (typeof window !== 'undefined' && !analytics) {
      analytics = new Analytics({
        writeKey,
      })
    }
  }

  async recordEvent(event: AnalyticsEvent): Promise<void> {
    if (!analytics) return

    try {
      await analytics.track({
        type: 'track',
        event: event.name,
        properties: {
          ...event.attributes,
          ...event.metrics,
          ...event.metadata,
        },
      })
    } catch (error) {
      console.error('Error recording Segment event:', error)
      throw error
    }
  }

  async identifyUser(userProfile: UserProfile): Promise<void> {
    if (!analytics || !userProfile.userId) return

    try {
      await analytics.identify({
        userId: userProfile.userId,
        traits: {
          name: userProfile.name,
          email: userProfile.email,
          plan: userProfile.plan,
          phoneNumber: userProfile.phoneNumber,
          age: userProfile.age,
          location: userProfile.location,
          ...userProfile.customProperties,
        },
      })
    } catch (error) {
      console.error('Error identifying user in Segment:', error)
      throw error
    }
  }

  async flushEvents(): Promise<void> {
    // The browser SDK automatically flushes events
    return Promise.resolve()
  }
}
