import aa, {InsightsMethodMap} from 'search-insights'

import {Product} from './products'

export class AlgoliaInsightsService {
  private static instance: AlgoliaInsightsService

  public static getInstance(): AlgoliaInsightsService {
    if (!AlgoliaInsightsService.instance) {
      AlgoliaInsightsService.instance = new AlgoliaInsightsService()
    }

    return AlgoliaInsightsService.instance
  }

  private constructor() {}

  initAlgoliaInsights = (user_id: string): void => {
    aa('init', {
      appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
      apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
    })
    aa('setAuthenticatedUserToken', user_id)
  }

  sendEvent = (
    user_id: string,
    eventName: keyof InsightsMethodMap,
    eventData: {
      objectIDs: string[]
      queryID?: string
      eventName: string
      positions?: number[]
    },
  ): void => {
    aa(eventName, {
      index: 'products',
      ...eventData,
      authenticatedUserToken: user_id,
    })
  }

  sendClickEvent = (user_id: string, product: Product, position?: number) => {
    this.sendEvent(
      user_id,
      product.query_id ? 'clickedObjectIDsAfterSearch' : 'clickedObjectIDs',
      {
        eventName: product.query_id
          ? 'Product Clicked After Search'
          : 'Product Clicked',
        queryID: product.query_id,
        objectIDs: [product.id],
        positions: position ? [position] : undefined,
      },
    )
  }

  sendViewEvent = (user_id: string, product: Product) => {
    this.sendEvent(user_id, 'viewedObjectIDs', {
      eventName: 'Product View',
      objectIDs: [product.id],
      queryID: product.query_id,
    })
  }

  sendSavedEvent = (user_id: string, product: Product) => {
    this.sendEvent(
      user_id,
      product.query_id ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs',
      {
        eventName: product.query_id
          ? 'Product Saved After Search'
          : 'Product Saved',
        queryID: product.query_id,
        objectIDs: [product.id],
      },
    )
  }

  sendAddToClosetEvent = (user_id: string, product: Product) => {
    this.sendEvent(
      user_id,
      product.query_id ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs',
      {
        eventName: product.query_id
          ? 'Product Add to Closet After Search'
          : 'Product Add to Closet',
        queryID: product.query_id,
        objectIDs: [product.id],
      },
    )
  }
}

export const algoliaInsightsService = AlgoliaInsightsService.getInstance()
