export const getBrowserContext = (): BrowserContext => {
  return {
    device: getDevice(),
    location: getLocation(),
  }
}

interface BrowserContext {
  device: string
  location: string
}

const getDevice = (): string => {
  return navigator.userAgent
}

const getLocation = (): string => {
  return navigator.language
}
