import {InternalAxiosRequestConfig} from 'axios'

export function userIdInterceptor(
  config: InternalAxiosRequestConfig<unknown>,
): InternalAxiosRequestConfig<unknown> {
  // Ignore this intercptor if we're not in the browser
  if (typeof window === 'undefined') {
    return config
  }

  const userId = localStorage.getItem('user_id')
  const gestUserId = localStorage.getItem('guest_id')

  config.headers['x-guest-id'] = userId ?? gestUserId

  return config
}
