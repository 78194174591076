'use client'

import {usePathname, useSearchParams} from 'next/navigation'
import {
  createContext,
  useCallback,
  useDeferredValue,
  useLayoutEffect,
  useState,
  type ReactNode,
} from 'react'

import {useDebounceValue} from '../use-debounce-value'

export const SearchContext = createContext<{
  value: string
  searchValue: string
  setSearchValue: (text: string) => void
} | null>(null)

interface SearchProviderProps {
  children: ReactNode
}

export function SearchProvider({children}: SearchProviderProps) {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const [value, setValue] = useState(() => searchParams.get('search') ?? '')
  const diffValue = useDeferredValue(value)
  const searchValue = useDebounceValue(diffValue, 500)

  useLayoutEffect(() => {
    setValue('')
  }, [pathname])

  const setSearchValue = useCallback((newValue: string) => {
    setValue(newValue)
  }, [])

  return (
    <SearchContext.Provider value={{value, searchValue, setSearchValue}}>
      {children}
    </SearchContext.Provider>
  )
}
