import {AxiosInstance, InternalAxiosRequestConfig} from 'axios'

export interface Interceptor<T> {
  (val: T): T | Promise<T>
}

/**
 * Apply request interceptor to axios instance
 * @param axiosInstance
 * @param interceptors
 */
export function applyRequestInterceptor(
  axiosInstance: AxiosInstance,
  interceptors: Interceptor<InternalAxiosRequestConfig<unknown>>[] = [],
): void {
  interceptors.forEach((interceptor) => {
    axiosInstance.interceptors.request.use(interceptor)
  })
}
