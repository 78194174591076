import {flushEvents, identifyUser, record} from 'aws-amplify/analytics'

import {AnalyticsEvent, UserProfile} from '../analytics.interface'

export class PinpointService {
  /**
   * Record a custom event with optional attributes and metrics
   */
  async recordEvent(event: AnalyticsEvent): Promise<void> {
    try {
      await record({
        name: event.name,
        attributes: event.attributes,
        metrics: event.metrics,
      })
    } catch (error) {
      console.error('Error recording analytics event:', error)
      throw error
    }
  }

  /**
   * Identify a user and associate them with their profile data
   */
  async identifyUser(userProfile: UserProfile): Promise<void> {
    try {
      await identifyUser({
        userId: userProfile.userId ?? '',
        userProfile: {
          name: userProfile.name,
          email: userProfile.email,
          location: userProfile.location,
          customProperties: {
            ...(userProfile.plan && {plan: userProfile.plan}),
            ...(userProfile.phoneNumber && {
              phoneNumber: userProfile.phoneNumber,
            }),
            ...(userProfile.age && {age: userProfile.age}),
            ...userProfile.customProperties,
          },
        },
      })
    } catch (error) {
      console.error('Error identifying user:', error)
      throw error
    }
  }

  /**
   * Manually flush any buffered events to the server
   */
  async flushEvents(): Promise<void> {
    try {
      await flushEvents()
    } catch (error) {
      console.error('Error flushing analytics events:', error)
      throw error
    }
  }
}
