import {AnalyticsService} from './analytics.service'

export * from './analytics.service'
export * from './analytics.interface'
export * from './amplify/pinpoint.service'
export * from './amplify/personalize.service'
export type {PersonalizeEvent} from './amplify/personalize.service'

// Create a singleton instance
export const analyticsService = new AnalyticsService()
