import {getAxiosInstance} from './axios-instance'
import {applyRequestInterceptor} from './interceptors'
import {tokenInterceptor} from './token-interceptor'
import {userIdInterceptor} from './user-id-interceptor'

export type {AxiosInstance} from 'axios'
export {AxiosError, isAxiosError, default as axios} from 'axios'

export {
  getAxiosInstance,
  applyRequestInterceptor,
  tokenInterceptor,
  userIdInterceptor,
}

export default getAxiosInstance
