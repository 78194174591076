import {InternalAxiosRequestConfig} from 'axios'

import {getAccessToken} from '../auth/get-access-token'

export async function tokenInterceptor(
  config: InternalAxiosRequestConfig<unknown>,
): Promise<InternalAxiosRequestConfig<unknown>> {
  const accessToken = await getAccessToken()

  if (!accessToken) {
    return config
  }

  config.headers.Authorization = `Bearer ${accessToken}`

  return config
}
