import {v4 as uuid} from 'uuid'

import {getAxiosInstance} from '@atorie/core/axios'

export interface User {
  id: string
  username: string
  email: string
  password: null
  role: string
  image_key: any
  phone_number?: string
  country_code?: string
  first_name?: string
  last_name?: string
  birth_date?: string
  auth_id: string
  auth_provider: string
  created_at: string
  updated_at: string
  metadata: Record<string, any>
  shopify_cart_id: string | null
  shopify_order_ids: string[] | null
  ai_clone: any
  latest_products_viewed: string[]
}

export type NotificationRequest = {
  status: 'success' | 'pending'
  search_id: string
}

export class UserService {
  private static instance: UserService

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService()
    }

    return UserService.instance
  }

  /**
   * Get the current user information
   */
  async me() {
    const axios = getAxiosInstance()
    const req = await axios.get<User>('/users/me')
    return req.data
  }

  async adminMe() {
    const axios = getAxiosInstance()
    const req = await axios.get<User>('/users/admin/me')
    return req.data
  }

  async updateCartId(cartId: string) {
    const axios = getAxiosInstance()
    const req = await axios.post<User>('/users/shopify/cart', {cart_id: cartId})
    return req.data
  }

  /**
   * Create a guest user
   * @todo this temporary solution should be removed once we have a proper auth flow
   * @returns
   */
  async createGuestUser() {
    const axios = getAxiosInstance()
    const id = uuid()
    const req = await axios.get<User>(`/users/guest/${id}`)

    return req.data
  }

  async getGuestUser(guest_id: string) {
    const axios = getAxiosInstance()
    const req = await axios.get<User>(`/users/guest/${guest_id}`)
    return req.data
  }

  /**
   * Update the guest user to a real user
   */
  async updateGuestUser(guest_id: string, user_data: User) {
    const axios = getAxiosInstance()
    const req = await axios.patch<User>(`/users/guest/${guest_id}`, user_data)
    return req.data
  }

  async updateUser(
    userId: string,
    updates: Partial<Omit<User, 'id' | 'role' | 'auth_id' | 'auth_provider'>>,
  ) {
    if (!userId) throw new Error('User ID is required')

    const axios = getAxiosInstance()
    try {
      const req = await axios.patch<User>(`/users/${userId}`, updates)
      return req.data
    } catch (error) {
      console.error('Failed to update user:', error)
      throw error
    }
  }

  async sendNotificationRequestFor({
    searchId,
  }: {
    searchId: string
  }): Promise<boolean> {
    if (!searchId.trim()) {
      throw new Error('searchId is required')
    }

    const axios = getAxiosInstance()

    try {
      const req = await axios.post(`/users/notification-requests`, {
        search_id: searchId,
      })

      return req.data.data.search_id === searchId
    } catch (e) {
      console.error('Failed to send notification request:', e)
      return false
    }
  }

  async getNotificationRequest({
    searchId,
  }: {
    searchId: string
  }): Promise<NotificationRequest> {
    if (!searchId.trim()) {
      throw new Error('searchId is required')
    }

    const axios = getAxiosInstance()

    try {
      const req = await axios.get(`/users/notification-requests/${searchId}`)

      return req.data.data
    } catch (e) {
      console.error('Failed to get notification request:', e)
      throw e
    }
  }

  async getOrders({first = 10, after}: {first?: number; after?: string} = {}) {
    const axios = getAxiosInstance()
    try {
      const req = await axios.get('/users/me/orders', {
        params: {first, ...(after && {after})},
      })
      return req.data
    } catch (error) {
      console.error('Failed to fetch user orders:', error)
      throw error
    }
  }

  async viewProduct(productId: string) {
    const axios = getAxiosInstance()
    const req = await axios.post(`/users/view-product`, {product_id: productId})
    return req.data
  }
}

export const userService = UserService.getInstance()
