'use client'

import mixpanel from 'mixpanel-browser'

import {
  AnalyticsEvent,
  IAnalyticsService,
  UserProfile,
} from '../analytics.interface'

export class MixpanelService implements IAnalyticsService {
  private initialized = false

  constructor(token: string) {
    if (!token) {
      throw new Error('Mixpanel token is required')
    }
    if (typeof window !== 'undefined' && !this.initialized) {
      this.initialized = true
      mixpanel.init(token)
    }
  }

  async recordEvent(event: AnalyticsEvent): Promise<void> {
    if (!this.initialized) return

    try {
      mixpanel.track(event.name, {
        ...event.attributes,
        ...event.metrics,
        ...event.metadata,
      })
    } catch (error) {
      console.error('Error recording Mixpanel event:', error)
      throw error
    }
  }

  async identifyUser(userProfile: UserProfile): Promise<void> {
    if (!this.initialized) return

    try {
      if (userProfile.userId) {
        mixpanel.identify(userProfile.userId)
      }
      mixpanel.people.set({
        ...userProfile,
        $name: userProfile.name,
        $email: userProfile.email,
      })
    } catch (error) {
      console.error('Error identifying user in Mixpanel:', error)
      throw error
    }
  }

  async flushEvents(): Promise<void> {
    return Promise.resolve()
  }
}
