import { userService } from "@atorie/api/users";
import { queryOptions, useQuery } from "@tanstack/react-query";
import { analyticsService } from "@atorie/api/analytics";

function authUserQueryOption() {
  return queryOptions({
    queryKey: ["me"],
    async queryFn() {
      try {
        const user = await userService.me();
        analyticsService.identifyUser({
          userId: user.id,
          name: user.first_name,
          email: user.email,
        });
        return user;
      } catch (error) {
        const guestUser = await userService.createGuestUser();

        if (typeof window !== "undefined") {
          window.localStorage.setItem("guest_id", guestUser.auth_id);
        }

        return guestUser;
      }
    },
  });
}

export function useAuthUserQuery() {
  return useQuery(authUserQueryOption());
}

export function useAuthUser() {
  const { data, refetch, ...rest } = useAuthUserQuery();

  return { user: data, refetch, ...rest };
}
